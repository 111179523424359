@mixin debug-border {
}
@mixin debug-borderX {
    border: 1px dashed lightgrey;
}
$black: #32485dc4;
$black-light: rgba(0, 0, 0, 0.37);
$turkis: #4eabc0;
$white: white;
$turkis-light: #a7d5e0;
$background-grey: #e8ebee;
$big-screen-max-width: 1100px;
$small-screen-min-width: 250px;
$font-size-standard: 14px;
$color-warn: red;

$color-success-background: #c2e4b4;
$color-info-background: #b4dee4;
$color-warn-background: #f1ba1436;
$color-error-background: #f3b4c8;
$primary-color: $black;
$accent-color: $turkis;
$warn-color: $color-warn;

@mixin big-screen {
    @media (min-width: 675px) {
        @content;
    }
}
@mixin small-screen {
    @media (max-width: 674px) {
        @content;
    }
}

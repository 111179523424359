@import "styles-variables.scss";

@mixin spo-header1 {
    text-align: center;
    width: 100%;
    font-weight: 500;
    font-size: 40px;
    margin: 0;
    word-wrap: break-word;
    margin: auto;
    width: fit-content;
}
@mixin spo-header2 {
    margin-top: 20px;
    text-align: center;
    width: 100%;
    font-weight: 200;
    font-size: 20px;
}
@mixin spo-center {
    text-align: center;
    width: 100%;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Roboto, "Helvetica Neue", sans-serif;

    font-size: $font-size-standard;
    color: $black !important;
    line-height: 1.1;
    word-wrap: break-word;
    letter-spacing: 0.1em;
}
h1 {
    @include spo-header1;
}
h2 {
    @include spo-header2;
}
h3 {
    margin: 0px;
    padding-bottom: 8px;
    text-align: justify;
    font-weight: 400;
    font-size: 14px;
}
p {
    margin: 0px;
    text-align: justify;
    font-weight: 200;
    font-size: 14px;
    line-height: 1.5em;
    margin-top: 32px;
    margin-bottom: 32px;
}

a {
    color: #0476ff;
}
.spo-center {
    @include spo-center;
}

.spo-container {
    .spo-container-title {
        @include spo-header1;
    }
    .spo-container-subtitle {
        @include spo-header2;
    }
    .spo-container-content {
        margin-top: 50px;
        margin-bottom: 50px;
        width: auto;
    }
}
.big-screen {
    .spo-container {
        .spo-container-content {
            margin-left: 100px;
            margin-right: 100px;
        }
    }
}
.small-screen {
    .spo-container {
        .spo-container-content {
            margin-left: 0px;
            margin-right: 0px;
        }
    }
}
.article {
    margin-top: 50px;
    margin-bottom: 50px;
}
.big-screen {
    .spo-content-box {
        // to be reomved - use .spo-container-content instead
        margin-top: 40px;
        margin-left: 100px;
        margin-right: 100px;
        width: auto;
    }
    .spo-field-container {
        margin: auto;
        margin-top: 5px;
        width: fit-content;
    }
    .spo-field-container > * {
        max-width: 400px;
        width: 100%;
        font-weight: 400;
        padding-bottom: 0px;
        width: 400px;
    }
}

.small-screen {
    .spo-content-box {
        margin-top: 40px;
        margin-left: 0px;
        margin-right: 0px;
        width: auto;
    }
    .spo-field-container {
        text-align: center;
        margin-top: 5px;
        width: fit-content;
    }
    .spo-field-container > * {
        width: 200px;
    }
}
.spo-border {
    border-color: $turkis-light;
    border-width: 1px;
    border-radius: 6px;
    border-style: solid;
}

.spo-centered-button {
    @include spo-center;
    margin-top: 20px;
    font-weight: 200;
    font-size: 20px;
}

// adjust the experience of mat buttons globally
.mdc-button {
    font-weight: 400 !important;
}
.mat-mdc-card {
    color: inherit;
}
.spo-font-color-white {
    color: $white !important;
}

.spo-font-color-turkis {
    color: $turkis !important;
}

.enter-room-field {
    margin-right: 20px;
    font-size: 12px !important;
    font-weight: 400 !important;
    padding-bottom: 0px !important;
    .mat-mdc-form-field-infix {
        min-height: 0px !important;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }
}

.big-screen {
    .enter-room-field {
        //   width: 400px;
    }
}

.spo-minutes-select {
    width: 64px;

    text-align: center !important;
    .mat-mdc-select {
        font-size: 14px !important;
        width: fit-content;
    }
    .mat-mdc-select-arrow {
        width: 0px;
        visibility: hidden;
    }
    .mat-mdc-form-field-infix {
        min-height: 0px !important;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }
}

.small-screen-modal-full {
    align-self: flex-end !important;
}

.small-screen-modal-full .mat-mdc-dialog-container .mdc-dialog__surface {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.mat-mdc-dialog-content {
    @include small-screen {
        height: 65vh;
    }
}
